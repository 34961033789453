//import * as React from 'react';
import React, { useState, useEffect } from "react";
import Typography from '../components/Typography';
import Logo from '../images/name-plate.png'
import { auto } from '@popperjs/core';
import Grid from "@mui/material/Grid";
import Form from "./Form";
import BgHeader from '../images/bg-header-mitsubishi.jpg';
import video from '../images/video.mp4';
import Title from '../images/title.png';

export default function ProductHero(props) {

	const { set, typeDialog, setTypeDialog, url } = props;
	
  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };

  return (
    <div className="video-container" id="inicio1">
	    <a id="inicio"> </a>	
      {!isMobile() ? 
        <video controls src={video} poster={BgHeader} autoPlay loop muted className='myVideo' style={{ marginTop: +isMobile()?  "95px" : "95px"}}/>
        : <img src={BgHeader} className='myVideo' style={{ marginTop: +isMobile()?  "95px" : "95px"}}/>
      }
      <div className="overlay" sx={{ alignItems: "center", textAlign:"center"}}>
        <Grid container spacing={2}
        justifyContent={isMobile()?  "center" : "center"}
        alignItems="center"
        >
          <Grid
          direction="row"
          justifyContent="center"
          alignItems="center"
          md={6}
          lg={6}
          paddingBottom={isMobile()?  "0px" : "0px"}
          >
            <img className="logo_blue"
            src={Logo}
            alt="increase priority"
            width="100%"
            height={auto}
            alignItems="center"
          />
          </Grid>
        </Grid>
        <Grid style={{ marginTop: "-40px" }} >
          <Form set={set} setTypeDialog={setTypeDialog} typeDialog={typeDialog} currentUrl={url}/>
        </Grid>
        {/* <Grid container spacing={2}
        justifyContent={isMobile()?  "center" : "end"}
        alignItems="center"
        >
          <Grid
          direction="row"
          justifyContent="center"
          alignItems="center"
          md={6}
          lg={6}
          >
            <img className="logo_blue"
            src={Logo}
            alt="increase priority"
            width="100%"
            height={auto}
            alignItems="center"
          />
          </Grid>
        </Grid> */}
        <div class="section-content has-diagonal" style={{ marginTop: '0', marginBottom: "0" }}>
          {/* <Grid container spacing={2} style={{ marginTop: +isMobile()?  '20px' :"20px", marginBottom: +isMobile()?  '20px' :"60px", placeContent: "space-around" }}>
            <Grid item xs={10} md={8} lg={8} sx={{ alignItems: "center"}}>
              <Typography style={{
                  fontSize: "clamp(1.3em, 5vw, 3rem)",
                  marginTop: "20px",
                  color: "white",
                  textAlign: "center",
                  alignContent: "center",
                  marginBottom: "0",
                  fontFamily: "mmcregular",
                  letterSpacing: "4",
                  lineHeight:"1em",
                  textTransform:"uppercase" 
                }} align="center" component="h1" color="white">
                  Poderosamente cómoda
              </Typography>
            </Grid>
          </Grid> */}
          
        </div>
      </div>
    </div>
  )
}
