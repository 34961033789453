import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import TextField from '../components/TextField';
import Stack from '@mui/material/Stack';
import fichaTecnica from './../../assets/MMDM-ficha tecnica-L200-MY25-01042024.pdf';
import preventa from './../../assets/preventa-UT-MMDM-2024-legales-2024-03-27-LEAB.pdf'

function Copyright() {
  return (
    <React.Fragment>
      {'© '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

const linkcss = {
  fontFamily: 'mmcregular',
  color: "white",
  fontSize: 12,
  textDecoration: 'underline',
  padding: "10px"
}
const linkcss2 = {
  fontFamily: 'mmcregular',
  color: "white",
  fontSize: 12,
  textDecoration: 'underline'
}

const text_footer = {
  fontFamily: 'mmcregular',
  color: "white",
  fontSize: 14
}

const text_footer2 = {
  fontFamily: 'mmcregular',
  color: "#9B9B9B",
  fontSize: 12
}

const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };

export default function AppFooter() {
  return (
    <Typography
      component="footer"
      sx={{ display: 'flex', bgcolor: 'secondary.light', background: 'black' }}
    >
      <Container>

      <Grid container spacing={2} alignItems="center" sx={{ marginTop: isMobile()?  '60px' :"80px", marginBottom: isMobile()?  '60px' :"80px" }}>

        <Grid item xs={12} sx={{marginBottom: isMobile()?  '60px' :"40px" }}>
          <center>
            <Typography sx={text_footer}>
              Si has apartado la nueva Mitsubishi L200 2025 y quieres una devolución, contacta al</Typography>
            <Typography sx={linkcss2} href="tel:+528006364878">
              800-63-MITSU (64878)</Typography>
          </center>
        </Grid>

        <Grid direction={isMobile()? "column" : "row"} container justifyContent={ isMobile()? "center" : "space-around" } alignItems="center" xs={12} sx={{marginBottom: isMobile()?  '60px' :"40px" }}>
          <Link sx={linkcss} href="https://www.mitsubishi-motors.mx/legal/aviso-de-privacidad" target="_blank">Aviso de privacidad</Link>
          <Link sx={linkcss} href="https://www.mitsubishi-motors.mx/legal/terminos-y-condiciones" target="_blank">Términos y condiciones</Link>
          <Link sx={linkcss} href="https://www.mitsubishi-motors.mx/content/dam/mitsubishi-motors-mx/descargadebrochure/preventa-l200-gsr/Preventa-UT-MMDM-2025-Legales-20241105-LEAB.pdf" target="_blank">Términos y condiciones de preventa</Link>
          <Link sx={linkcss} href="https://www.mitsubishi-motors.mx/legal/legales" target="_blank">Legales</Link>
          <Link sx={linkcss} href="https://www.mitsubishi-motors.mx/content/dam/mitsubishi-motors-mx/descargadebrochure/l200_2025/Mitsubishi-Motors-Mx-Ficha-Tecnica-L200-GSR-MY25.pdf" target="_blank">Ficha técnica</Link>
        </Grid>

        <Grid item xs={12} sx={{marginBottom: isMobile()?  '60px' :"40px" }}>
          <center>
            <Typography sx={text_footer2}>
              © Mitsubishi Motors Corporation 2024. All rights reserved.</Typography>
          </center>
        </Grid>

      </Grid>

      </Container>

    </Typography>

  );
}
