import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from '../components/Typography';

import img1 from "../images/360_/01-mitsubishi-orange.png";
import img2 from "../images/360_/02-mitsubishi-silver.png";
import img3 from "../images/360_/03-mitsubishi-white.png";
import img4 from "../images/360_/04-mitsubishi-black.png";
import img5 from "../images/360_/05-mitsubishi-orange.png";
import img6 from "../images/360_/06-mitsubishi-silver.png";
import img7 from "../images/360_/07-mitsubishi-white.png";
import img8 from "../images/360_/08-mitsubishi-black.png";
import img9 from "../images/360_/09-mitsubishi-orange.png";
import img10 from "../images/360_/10-mitsubishi-silver.png";
import img11 from "../images/360_/11-mitsubishi-white.png";
import img12 from "../images/360_/12-mitsubishi-black.png";


import { EffectCoverflow, Navigation } from "swiper";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};


function ProductValues() {
  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };
  return (
    <Box id="diseno"
      component="section"
      sx={{
        display: "flex",
        overflow: "hidden",
        background:
          "linear-gradient(97deg, rgba(33,33,33,1) 0%, rgba(25,25,25,1) 22%, rgba(26,26,26,1) 68%, rgba(39,39,39,1) 86%, rgba(37,36,36,1) 100%)"
      }}
    >
      <Grid
        container
        style={{
          marginTop: isMobile()?  '60px' :"120px",
          marginBottom: isMobile()?  '100px' :"140px",
          display: "flex",
          position: "relative",
          placeContent: "space-around",
        }}
      >
        {/* <Grid item xs={10} sm={6} xl={4}>
          <Typography style={{
            marginTop: isMobile()?  '-20px' :"-20px",
            marginBottom: '30px',
            fontFamily: "mmcregular",
            lineHeight:"1.1em",
            fontSize: "clamp(1rem, 5vw, 0.2rem)"     
          }} align="center" component="h1" color="white">
            Sujeto a cambio por concepto*
          </Typography>
        </Grid> */}
        <Grid
          item
          xs={10} md={12}
          style={{
            fontSize: "clamp(2em, 5vw, 3rem)",
            marginTop: "0",
            color: "white",
            textAlign: "center",
            alignContent: "center",
            marginBottom: "50px",
            fontFamily: "mmcregular",
            letterSpacing: "4",
            lineHeight:"1em",
            textTransform:"uppercase"
          }}
        >
          Poder que se nota.​
        </Grid>
        <Grid item xs={10} sm={6} xl={4}>
          <Typography style={{
            marginTop: isMobile()?  '-20px' :"-20px",
            fontFamily: "mmcregular",
            lineHeight:"1.1em",
            fontSize: "clamp(1.2rem, 5vw, 0.4rem)"     
          }} align="center" component="h1" color="white">
            Roba miradas con el poderoso diseño de la Nueva Mitsubishi L200 GSR 2025 que fue creado para superar cualquier camino sin importar el terreno.​
          </Typography>
        </Grid>


        <Grid container sx={{ marginTop: isMobile()?  '10px' :"-50px", }}>
          <Swiper
            navigation={true}
            slidesPerView={1}
            loop={true}
            centeredSlides={true}
            effect={"coverflow"}
            spaceBetween={isMobile()? 200 : 1}
            modules={[Navigation, EffectCoverflow]}
            className="mySwiper"
            coverflowEffect={{
              rotate: 0,
              stretch: 10,
              depth: 1500,
              modifier: 1,
              slideShadows: false,
            }}
          >
            <SwiperSlide style={{
              display: "flex",
              flexDirection: "column",
              alignItems: 'center'
            }}>

              <Box
                component="img"
                src={img1}
                alt="Orange"
                sx={{ maxWidth: '75%' }}
              />
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(1.1rem, 5vw, 2.2rem)",
                  fontStyle: "bold",
                  fontFamily: "mmcoffice",
                  marginBottom: "20px",
                  marginTop: isMobile()?  '-20px' :"-85px",
                  letterSpacing: "0.05em"
                }}
              >
                Yamabuki Orange Metallic
              </Typography>
            </SwiperSlide>
            <SwiperSlide style={{
              display: "flex",
              flexDirection: "column",
              alignItems: 'center'
            }}>

              <Box
                component="img"
                src={img2}
                alt="Silver"
                sx={{ maxWidth: '75%' }}
              />
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(1.1rem, 5vw, 2.2rem)",
                  fontStyle: "bold",
                  fontFamily: "mmcoffice",
                  marginBottom: "20px",
                  marginTop: isMobile()?  '-20px' :"-85px",
                  letterSpacing: "0.05em"
                }}
              >
                Graphite Gray Metallic
              </Typography>
            </SwiperSlide>
            <SwiperSlide style={{
              display: "flex",
              flexDirection: "column",
              alignItems: 'center'
            }}>

              
              <Box
                component="img"
                src={img3}
                alt="White"
                sx={{ maxWidth: '75%' }}
              />
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(1.1rem, 5vw, 2.2rem)",
                  fontStyle: "bold",
                  fontFamily: "mmcoffice",
                  marginBottom: "20px",
                  marginTop: isMobile()?  '-10px' :"-75px",
                  letterSpacing: "0.05em"
                }}
              >
                White Diamond
              </Typography>
            </SwiperSlide>
            <SwiperSlide style={{
              display: "flex",
              flexDirection: "column",
              alignItems: 'center'
            }}>

              <Box
                component="img"
                src={img4}
                alt="Black"
                sx={{ maxWidth: '66%', marginTop: '10px' }}
              />
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(1.1rem, 5vw, 2.2rem)",
                  fontStyle: "bold",
                  fontFamily: "mmcoffice",
                  marginBottom: "20px",
                  marginTop: isMobile()?  '-10px' :"-75px",
                  letterSpacing: "0.05em"
                }}
              >
                Jet Black Mica
              </Typography>
            </SwiperSlide>
            <SwiperSlide style={{
              display: "flex",
              flexDirection: "column",
              alignItems: 'center'
            }}>

              <Box
                component="img"
                src={img5}
                alt="Orange"
                sx={{ maxWidth: '68%' }}
              />
              
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(1.1rem, 5vw, 2.2rem)",
                  fontStyle: "bold",
                  fontFamily: "mmcoffice",
                  marginBottom: "20px",
                  marginTop: isMobile()?  '-10px' :"-75px",
                  letterSpacing: "0.05em"
                }}
              >
                Yamabuki Orange Metallic
              </Typography>
            </SwiperSlide>
            <SwiperSlide style={{
              display: "flex",
              flexDirection: "column",
              alignItems: 'center'
            }}>

              <Box
                component="img"
                src={img6}
                alt="Silver"
                sx={{ maxWidth: '68%' }}
              />
              
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(1.1rem, 5vw, 2.2rem)",
                  fontStyle: "bold",
                  fontFamily: "mmcoffice",
                  marginBottom: "20px",
                  marginTop: isMobile()?  '-10px' :"-75px",
                  letterSpacing: "0.05em"
                }}
              >
                Graphite Gray Metallic
              </Typography>
            </SwiperSlide>
            <SwiperSlide style={{
              display: "flex",
              flexDirection: "column",
              alignItems: 'center'
            }}>

              <Box
                component="img"
                src={img7}
                alt="White"
                sx={{ maxWidth: '68%' }}
              />
              
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(1.1rem, 5vw, 2.2rem)",
                  fontStyle: "bold",
                  fontFamily: "mmcoffice",
                  marginBottom: "20px",
                  marginTop: isMobile()?  '-10px' :"-75px",
                  letterSpacing: "0.05em"
                }}
              >
                White Diamond
              </Typography>
            </SwiperSlide>
            <SwiperSlide style={{
              display: "flex",
              flexDirection: "column",
              alignItems: 'center'
            }}>

              <Box
                component="img"
                src={img8}
                alt="Black"
                sx={{ maxWidth: '68%' }}
              />
              
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(1.1rem, 5vw, 2.2rem)",
                  fontStyle: "bold",
                  fontFamily: "mmcoffice",
                  marginBottom: "20px",
                  marginTop: isMobile()?  '-10px' :"-75px",
                  letterSpacing: "0.05em"
                }}
              >
                Jet Black Mica
              </Typography>
            </SwiperSlide>
            <SwiperSlide style={{
              display: "flex",
              flexDirection: "column",
              alignItems: 'center'
            }}>

              <Box
                component="img"
                src={img9}
                alt="Orange"
                sx={{ maxWidth: '68%' }}
              />
              
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(1.1rem, 5vw, 2.2rem)",
                  fontStyle: "bold",
                  fontFamily: "mmcoffice",
                  marginBottom: "20px",
                  marginTop: isMobile()?  '-10px' :"-75px",
                  letterSpacing: "0.05em"
                }}
              >
                Yamabuki Orange Metallic
              </Typography>
            </SwiperSlide>
            <SwiperSlide style={{
              display: "flex",
              flexDirection: "column",
              alignItems: 'center'
            }}>

              <Box
                component="img"
                src={img10}
                alt="Silver"
                sx={{ maxWidth: '68%' }}
              />
              
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(1.1rem, 5vw, 2.2rem)",
                  fontStyle: "bold",
                  fontFamily: "mmcoffice",
                  marginBottom: "20px",
                  marginTop: isMobile()?  '-10px' :"-75px",
                  letterSpacing: "0.05em"
                }}
              >
                Graphite Gray Metallic
              </Typography>
            </SwiperSlide>
            <SwiperSlide style={{
              display: "flex",
              flexDirection: "column",
              alignItems: 'center'
            }}>

              <Box
                component="img"
                src={img11}
                alt="White"
                sx={{ maxWidth: '68%' }}
              />
              
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(1.1rem, 5vw, 2.2rem)",
                  fontStyle: "bold",
                  fontFamily: "mmcoffice",
                  marginBottom: "20px",
                  marginTop: isMobile()?  '-10px' :"-75px",
                  letterSpacing: "0.05em"
                }}
              >
                White Diamond
              </Typography>
            </SwiperSlide>
            <SwiperSlide style={{
              display: "flex",
              flexDirection: "column",
              alignItems: 'center'
            }}>

              <Box
                component="img"
                src={img12}
                alt="Black"
                sx={{ maxWidth: '68%' }}
              />
              
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(1.1rem, 5vw, 2.2rem)",
                  fontStyle: "bold",
                  fontFamily: "mmcoffice",
                  marginBottom: "20px",
                  marginTop: isMobile()?  '-10px' :"-75px",
                  letterSpacing: "0.05em"
                }}
              >
                Jet Black Mica
              </Typography>
            </SwiperSlide>
          </Swiper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProductValues;
