import * as React from 'react';
import ProductCategories from '../modules/views/ProductCategories';
import ProductSmokingHero from '../modules/views/ProductSmokingHero';
import AppFooter from '../modules/views/AppFooter';
import ProductHero from '../modules/views/ProductHero';
import ProductValues from '../modules/views/ProductValues';
import Endsistem from '../modules/views/endsistem';
import ProductCTA from '../modules/views/ProductCTA';
import AppAppBar from '../modules/views/AppAppBar';
import withRoot from '../modules/withRoot';

function Index() {

  const [fin, setfin] = React.useState(false);
  const [typeDialog, setTypeDialog] = React.useState('')

  const [currentUrl, setCurrentUrl] = React.useState(false);

  /*if (!currentUrl) {
    setCurrentUrl(window.location.href); 
  }*/
  React.useEffect(() => {
    setCurrentUrl(window.location.href);
    // const newUrl = window.location.href.split('/?');
    // history.pushState(null, 'FeaturePoints Login', newUrl[0]);
    // console.log(window.location.href, newUrl[0]);
  }, []);

  


  console.log("fidnss", fin)
  return (
    <React.Fragment>
      <AppAppBar set={setfin} fin={fin} setTypeDialog={setTypeDialog} typeDialog={typeDialog} isMenuOption={true} url={currentUrl}/>
      {fin == false ? (
        <><ProductHero set={setfin} setTypeDialog={setTypeDialog} typeDialog={typeDialog} url={currentUrl}/><ProductValues /><ProductCategories set={setfin} setTypeDialog={setTypeDialog} typeDialog={typeDialog} url={currentUrl}/></>

      ) : <Endsistem set={setfin} fin={fin} typeDialog={typeDialog} />}
      <AppFooter sx={{ zINdex: "999" }} />
    </React.Fragment>
  );
}

export default withRoot(Index);
