import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from '../components/Typography';

export default function SectionTypeA(props) {
  const { isMobile, img, title, subtitle, p1, p2 } = props
  return (
    <section id={`section${title}`}>
      <div class="section-content has-diagonal" style={{ marginTop: isMobile()?  '10px' :"40px", marginBottom: "160px" }}>
        <div style={{ margin: "1%" }}>
          <Grid container columnSpacing={1} style={{ marginTop: "0px", marginBottom: "0px", placeContent: "space-around" }}>
            <Grid item xs={12} md={12} lg={12} sx={{ alignItems: "center"}}>
              <Typography style={{
                  fontSize: "clamp(2em, 5vw, 3rem)",
                  marginTop: isMobile ? '60px' : "0px",
                  color: "white",
                  textAlign: "center",
                  alignContent: "center",
                  marginBottom: "50px",
                  fontFamily: "mmcregular",
                  letterSpacing: "4",
                  lineHeight:"1em",
                  textTransform:"uppercase"
                }} align="center" component="h1" color="white">
                  {title}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ alignItems: "center", margin:"0 auto", justifyContent: "center"}}>
            <Grid item xs={12} sm={6} md={6} sx={{ alignItems: "start"}}>
              <center>
                <Box
                  component="img"
                  src={img}
                  alt={title}
                  sx={{ maxWidth: isMobile ? '90%' : "80%" }}
                />
              </center>
            </Grid>
            <Grid item xs={12} sm={6} md={6} sx={{ alignItems: "center", paddingLeft: "30px", paddingRight: "30px"}}>
              <Typography style={{
                  fontSize: "clamp(1.5em, 5vw, 2rem)",
                  marginTop: isMobile ? '60px' : "0px",
                  color: "white",
                  textAlign: "left",
                  alignContent: "center",
                  marginBottom: "50px",
                  fontFamily: "mmcregular",
                  letterSpacing: "4",
                  lineHeight:"1em",
                  textTransform: "uppercase"
                }} align="center" component="h1" color="white">
                  {subtitle}
              </Typography>
              <Typography style={{
                marginTop: isMobile ? '-20px' : "-20px",
                marginBottom: "60px",
                textAlign: "left",
                fontFamily: "mmcregular",
                lineHeight:"1.1em",
                fontSize: "clamp(1.2rem, 5vw, 0.4rem)",
              }} align="center" component="h1" color="white">
                {p1}
                <br/><br/>
                {p2}
              </Typography>
            </Grid>
          </Grid>

        </div>
      </div>
      <div class="section-mask bg-option-2 has-parallax" style={{
        marginTop: '0px',
        background:
          "#000000"
      }}>
        <hr style={{ marginTop: "-2px", height: "3px" }} color="#cf0303" />
      </div>
    </section>
  )
}